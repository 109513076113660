<template>
  <div
    v-if="loading"
    class="mb-4 h-6 w-4/5 animate-pulse rounded-full bg-black/6 md:w-72"
  />
  <nav v-else class="text-gray-900" aria-label="breadcrumbs">
    <ol
      class="flex flex-nowrap items-center overflow-x-auto overflow-y-hidden pb-3 text-sm md:text-md"
    >
      <li
        v-for="(breadcrumb, i) in breadcrumbs"
        :key="breadcrumb.path + i"
        :aria-current="last === i && 'page'"
        class="flex items-center whitespace-nowrap"
      >
        <template v-if="shouldLinkBreadcrumb(to(breadcrumb.path))">
          <NuxtLink
            :to="to(breadcrumb.path)"
            class="text-primary-default hover:underline"
          >
            {{ breadcrumb.name }}
          </NuxtLink>
        </template>
        <span v-else class="font-normal text-black/54">
          {{ breadcrumb.name }}
        </span>
        <DynamicPictogram
          v-if="last !== i"
          name="chevron"
          class="rotate-180 px-1 text-gray-500"
        />
      </li>
    </ol>
  </nav>
</template>
<script setup lang="ts">
const localePath = useLocalePath();
const { locale } = useI18n();
const route = useRoute();

const { rootPath } = useBaseCountryConfig();
const language = useLanguage();

interface BreadcrumbItem {
  name: string;
  path: string;
}

const props = defineProps({
  /**
   * List of breadcrumbs (array of nested objects: `[ { name, path } ]`)
   */
  breadcrumbs: {
    type: Array as () => BreadcrumbItem[],
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const last = computed(() => props.breadcrumbs.length - 1);

const shouldLinkBreadcrumb = (path: string) => path && path !== route.path;

const to = (path: string) => {
  /* TODO: Hack to prevent /se/se on productgrid pages, can we solve it another way */
  if (path?.startsWith(`/${locale.value}`)) {
    return path;
  }

  // Hack to prevent the /sv/butik being added to a base path
  if (path?.includes(`/${rootPath[language]}`)) {
    return localePath(path.replace(`/${rootPath[language]}`, ''));
  }

  return localePath(path);
};
</script>
